import React from "react";
import "./style.css";
// react reveal
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

const RoadMap = () => {
  return (
    <>
      <div id="roadmap" className="roadmap">
        <Fade bottom duration={1000} delay={1100}>
          <div>
            <h1>ROADMAP</h1>
          </div>
        </Fade>
        <Slide bottom duration={1200} delay={1100}>
          <p>
            BOTHEAD is much more than a rare NFT collectible with
            top-of-the-line artwork. BOTHEAD is your ticket to an exciting,
            growing movement within the NFT/crypto communities that challenges
            the notions and limits of what roles NFTs can play socially,
            culturally, commercially, and more!
          </p>
          <p>
            Our project has always been about seeing the beauty all around us
            and promoting good vibes, and we think there’s no better way to
            achieve that than by coming together as a community and sharing with
            one another. BOTHEAD together strong! That is why we want YOU, the
            BOTHEAD family, to have a voice in the future of this project. We
            assure you that we’re here for the long haul and above all, we value
            our community members and are determined not to let you down! That
            said, here is our roadmap. Please keep in mind some details are
            subject to change as we receive more feedback and ideas from the
            community.
          </p>
        </Slide>
        <div className="container">
          <div className="timeline">
            <div className="timeline-container primary">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">PRESALE</span>
                </h4>
              </div>
              <div className="timeline-body">
  
                <p>
                  We will be offering presale access to avoid gas wars and
                  reward early supporters of the project. Check discord for most
                  up-to-date info!
                </p>
              </div>
            </div>
            <div className="timeline-container danger">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">COMMUNITY WALLET</span>
                </h4>
              </div>
              <div className="timeline-body">
                <p>
                  At 50% sold, we will activate the community wallet. 2% of
                  total mint proceeds will be used as YOU see fit
                </p>
              </div>
            </div>
            <div className="timeline-container success">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">AIRDROPS + FUTURE COLLECTION </span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  Following a successful drop, we will announce our plans for
                  the future of BOTHEAD. We are working on collabs which will
                  combine the generative art of our NFTs with fine art
                  photography, truly a first for the space! BOTHEAD owners will
                  receive free airdrops and special access to this new
                  collection.
                </p>
              </div>
            </div>
            <div className="timeline-container warning">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">COMMUNITY EVENTS & ACTIVITIES</span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  We want to keep the community engaged and having fun, which is
                  why we’ll be hosting regular events and activities! Everyone
                  will have a chance to get involved leading up to launch, after
                  which BOTHEAD owners will have exclusive access to certain
                  bigger ticket events and prizes. Visit our socials and head
                  over to our discord server to join the fun!
                </p>
              </div>
            </div>
            <div className="timeline-container">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">FIND THE LOST BOTHEADS </span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  Three BOTHEADs will be withheld from the sale. They’ve managed
                  to get lost exploring our beautiful world and it’s up to YOU
                  to find them! Instructions and clues will be posted on our
                  socials leading up to launch, with the final clues released to
                  members after the sale. First one to solve each puzzle will be
                  airdropped the missing BOTHEAD NFT!
                </p>
              </div>
            </div>
            <div className="timeline-container info">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">MEMBERS-ONLY ACCESS</span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  Owners of BOTHEAD NFTs will have exclusive access to a private
                  Clubhouse room, Discord server, and more! After our sale, we
                  will open the online store offering quality merch + gear.
                </p>
              </div>
            </div>
            <div className="timeline-container">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">ORIGINAL BOTHEAD PAINTING</span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  We are excited to announce that a world-renowned artist is
                  creating a 122 x 122cm original BOTHEAD painting to be
                  auctioned off after our drop! Keep an eye out for more details
                  and sneak peeks on our socials!
                </p>
              </div>
            </div>
            <div className="timeline-container green">
              <div className="timeline-icon">
                <h4 className="timeline-title">
                  <span className="badge">BOTHEAD FOREVER</span>
                </h4>{" "}
              </div>
              <div className="timeline-body">
                <p>
                  Again, BOTHEAD is here to stay. We will constantly be cooking
                  up fun and exciting ways to connect and grow the community. As
                  mentioned, we are already working on an exciting follow-up
                  collection which will be made available to original BOTHEAD
                  holders for free! Additionally, we are looking forward to
                  releasing 1/1 editions and collaborating with other artists
                  and projects both within the NFT space and beyond! To say
                  we’re excited about the future of BOTHEAD would be a massive
                  understatement! We can’t wait to have you join us and see what
                  amazing things we can all accomplish together!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadMap;
