import { createModel } from "@rematch/core";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import { Bothead__factory } from "../contract/types/ethers-contracts/factories/Bothead__factory";
import { Bothead } from "../contract/types/ethers-contracts/Bothead";
import { RootModel } from ".";
import { PROD_CONFIG, TEST_CONFIG } from "../config";

const CONFIG = PROD_CONFIG;
// const CONFIG = TEST_CONFIG;

declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

export interface UserInfo {
  address: string;
  balance?: string;
  unlockableAmount?: string;
  pendingUnlockAmount?: string;
}

interface MainState {
  showMintModal: boolean;
  showPreMintModal: boolean;
  userInfo: UserInfo;
  ethersProvider?: ethers.providers.Web3Provider;
  signer?: ethers.providers.JsonRpcSigner;
  BotheadContract?: Bothead;
  alertState: AlertState;
  isMainNet: boolean;
  isContractInit?: boolean;
  soldNum: number;
  avaliablePreMintNum: number;
  UNIT_PRICE: number;
  PRE_UNIT_PRICE: number;
  canRewardTimes: number;
  publicSaleState: boolean;
  preSaleState: boolean;
}

interface AlertState {
  open: boolean;
  type: "success" | "info" | "warning" | "error";
  content: string;
}

const initAlertState: AlertState = {
  open: false,
  type: "success",
  content: "",
};

const initMainState: MainState = {
  userInfo: {
    address: "",
  },
  showMintModal: false,
  showPreMintModal: false,
  alertState: initAlertState,
  isMainNet: true,
  soldNum: 0,
  avaliablePreMintNum: 0,
  UNIT_PRICE: 0.08,
  PRE_UNIT_PRICE: 0.075,
  canRewardTimes: 0,
  publicSaleState: false,
  preSaleState: false,
};

export const main = createModel<RootModel>()({
  state: initMainState,
  reducers: {
    setFields(state, fields: Partial<MainState>): MainState {
      return {
        ...state,
        ...fields,
      };
    },
    openAlert(state, fields: Partial<AlertState>): MainState {
      let newAlertState = {
        ...state.alertState,
        ...fields,
        open: true,
      };
      return {
        ...state,
        alertState: newAlertState,
      };
    },
    closeAlert(state): MainState {
      return {
        ...state,
        alertState: {
          ...state.alertState,
          open: false,
        },
      };
    },
    setUserInfo(state, fields: Partial<UserInfo>): MainState {
      let newUserInfo = {
        ...state.userInfo,
        ...fields,
      };
      return {
        ...state,
        userInfo: newUserInfo,
      };
    },
  },
  effects: (dispatch) => ({
    async init() {
      const ethereum = window.ethereum;
      if (ethereum) {
        let ethersProvider = new ethers.providers.Web3Provider(ethereum, "any");
        let signer = ethersProvider.getSigner();
        dispatch.main.setFields({
          ethersProvider,
          signer,
        });
        dispatch.main.connectWallet();
        dispatch.main.initContract();
      }
    },
    async initContract(_payload: any, state) {
      let { ethersProvider, signer } = state.main;
      if (ethersProvider && signer) {
        // 连接合约
        let BotheadContract = Bothead__factory.connect(
          CONFIG.BOTHEAD_CONTRACT,
          signer
        );
        let netWorkInfo = await ethersProvider.getNetwork();
        const isMainNet = netWorkInfo.chainId === CONFIG.NET_ID;
        dispatch.main.setFields({
          isMainNet,
          isContractInit: true,
          BotheadContract,
        });
        dispatch.main.updateInfo();
      }
    },
    async updateInfo(_payload: any, state) {
      let { BotheadContract } = state.main;
      if (BotheadContract) {
        try {
          let soldNum = await BotheadContract.getSoldNum();
          let avaliablePreMintNum = await BotheadContract.getPreMintAddrNums();
          let UNIT_PRICE = await BotheadContract.price();
          let PRE_UNIT_PRICE = await BotheadContract.prePrice();
          let publicSaleState = await BotheadContract.publicSale();
          let preSaleState = await BotheadContract.preSale();
          dispatch.main.setFields({
            publicSaleState,
            preSaleState,
            soldNum: soldNum.toNumber(),
            avaliablePreMintNum: avaliablePreMintNum.toNumber(),
            UNIT_PRICE: new BigNumber(
              ethers.utils.formatEther(UNIT_PRICE)
            ).toNumber(),
            PRE_UNIT_PRICE: new BigNumber(
              ethers.utils.formatEther(PRE_UNIT_PRICE)
            ).toNumber(),
          });
          console.log(state.main);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async connectWallet(_payload: any, state) {
      let walletAccounts;
      const ethereum = window.ethereum;
      if (ethereum) {
        try {
          walletAccounts = await ethereum.request!({
            method: "eth_requestAccounts",
          });
          dispatch.main.setUserInfo({ address: walletAccounts[0] });
        } catch (error: any) {
          if (error?.code === 4001) {
            // 用户取消连接网站
            dispatch.main.openAlert({
              type: "error",
              content: error.message,
            });
          }
        }
      }
    },
    async preMint(payload: number, state) {
      let { BotheadContract, PRE_UNIT_PRICE } = state.main;
      if (BotheadContract && payload !== 0) {
        let mintHash;
        try {
          // PRE MINT
          mintHash = await BotheadContract.preMint(payload, {
            value: ethers.utils.parseEther(
              new BigNumber(payload).times(PRE_UNIT_PRICE).toString()
            ),
          });
          let result = await mintHash.wait();
          if (result.status === 1) {
            dispatch.main.openAlert({
              type: "success",
              content: "Congratulations! Transaction Confirmed!",
            });
            dispatch.main.setFields({
              showMintModal: false,
            });
            // dispatch.main.updateInfo();
          }
        } catch (MetaMaskError: any) {
          const { error } = JSON.parse(JSON.stringify(MetaMaskError));
          if (MetaMaskError?.code === 4001) {
            dispatch.main.openAlert({
              type: "error",
              content: MetaMaskError.message,
            });
          } else {
            dispatch.main.openAlert({
              type: "error",
              content: error?.message,
            });
          }
        }
      }
    },
    async mint(payload: number, state) {
      let { BotheadContract, UNIT_PRICE } = state.main;
      if (BotheadContract && payload !== 0) {
        let mintHash;
        try {
          mintHash = await BotheadContract.mint(payload, {
            value: ethers.utils.parseEther(
              new BigNumber(payload).times(UNIT_PRICE).toString()
            ),
          });
          let result = await mintHash.wait();
          if (result.status === 1) {
            dispatch.main.openAlert({
              type: "success",
              content: "Congratulations! Transaction Confirmed!",
            });
            dispatch.main.setFields({
              showMintModal: false,
            });
            // dispatch.main.updateInfo();
          }
        } catch (MetaMaskError: any) {
          const { error } = JSON.parse(JSON.stringify(MetaMaskError));
          if (MetaMaskError?.code === 4001) {
            dispatch.main.openAlert({
              type: "error",
              content: MetaMaskError.message,
            });
          } else {
            dispatch.main.openAlert({
              type: "error",
              content: error?.message,
            });
          }
        }
      }
    },
  }),
});
