import React from "react";
import ReactDOM from "react-dom";
import { ethers } from "ethers";
import { Provider } from "react-redux";
import { HashRouter as Router, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "../src/model";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

const ethereum: any = window.ethereum;

if (ethereum) {
  ethereum.on("chainChanged", function (networkIDstring: any) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  });

  ethereum.on("accountsChanged", function (accounts: any) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <Provider store={store}>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
