import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import BigNumber from "bignumber.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { RootState, RootDispatch } from "./model";
import { useRematch } from "./utils/useRematch";
import BoxMovie from "./assets/box.mp4";
import Home from "./pages/Home";
import "./App.css";

const mapState = (state: RootState) => ({
  ...state.main,
  loading: state.loading,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  ...dispatch.main,
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [
    {
      isMainNet,
      alertState,
      showMintModal,
      showPreMintModal,
      loading,
      avaliablePreMintNum,
    },
    { init, setFields, openAlert, mint, preMint, closeAlert },
  ] = useRematch(mapState, mapDispatch);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mintNumber, setMintNumber] = useState("1");
  const [preMintNumber, setPreMintNumber] = useState("1");

  const MAX_MINT_NUMBER_SALES = 20;

  const mintNumberInvalid = useMemo(() => {
    const mintNumberBignumber = new BigNumber(mintNumber);
    return (
      mintNumberBignumber.isNaN() ||
      !mintNumberBignumber.isInteger() ||
      mintNumberBignumber.isZero() ||
      mintNumberBignumber.isGreaterThan(MAX_MINT_NUMBER_SALES)
    );
  }, [mintNumber]);

  const preMintNumberInvalid = useMemo(() => {
    const preMintNumberBignumber = new BigNumber(preMintNumber);
    return (
      preMintNumberBignumber.isNaN() ||
      !preMintNumberBignumber.isInteger() ||
      preMintNumberBignumber.isZero() ||
      preMintNumberBignumber.isGreaterThan(avaliablePreMintNum)
    );
  }, [preMintNumber, avaliablePreMintNum]);

  return (
    <div className="App">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!isMainNet}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          You are not connect to the Ethereum network!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertState.open}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert severity={alertState.type} onClose={closeAlert}>
          {alertState.content}
        </Alert>
      </Snackbar>
      <Dialog
        open={showMintModal}
        style={{
          fontFamily: '"Orbitron", sans-serif !important',
        }}
        onClose={() => {
          setFields({
            showMintModal: false,
          });
        }}
      >
        <DialogTitle
          style={{
            background: "#001417",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
            fontFamily: '"Orbitron", sans-serif',
          }}
          onClick={() => {
            openAlert({
              type: "success",
              content: "Congratulations! Transaction Confirmed!",
            });
          }}
        >
          {loading.effects.main.mint ? (
            <Fragment>
              PENDING...
              <CircularProgress size="24px" style={{ marginLeft: "20px" }} />
            </Fragment>
          ) : (
            <span style={{ fontFamily: '"Orbitron", sans-serif' }}>
              Mint your own BOTHEAD
            </span>
          )}
        </DialogTitle>
        <DialogContent style={{ background: "#001417" }}>
          {loading.effects.main.mint ? (
            <div className="banner">
              <video
                autoPlay
                loop
                muted
                width="100%"
                className="banner-img"
                preload="auto"
              >
                <source src={BoxMovie} type="video/mp4" />
              </video>
            </div>
          ) : (
            <Fragment>
              <DialogContentText
                style={{
                  marginBottom: "24px",
                  fontFamily: '"Orbitron", sans-serif',
                }}
              >
                {/* <b>PRE MINT MAX MINT NUMBER: {avaliablePreMintNum}</b> */}
                <b>MAX MINT NUMBER: {MAX_MINT_NUMBER_SALES}</b>
              </DialogContentText>
              <DialogContentText
                style={{
                  marginBottom: "24px",
                  fontFamily: '"Orbitron", sans-serif',
                }}
              >
                After your successfully minting, after a few minutes, you can
                see your BOTHEAD NFT on OpenSea.
              </DialogContentText>
              <TextField
                autoFocus
                error={mintNumberInvalid}
                margin="dense"
                type="text"
                value={mintNumber}
                onChange={(e) => {
                  setMintNumber(e.target.value);
                }}
                label={`Mint Number`}
                fullWidth
                style={{ fontFamily: '"Orbitron", sans-serif' }}
                variant="standard"
                helperText={mintNumberInvalid ? "Number invalid." : ""}
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions style={{ background: "#001417" }}>
          <LoadingButton
            loading={loading.effects.main.mint}
            loadingIndicator="Pending"
            variant="text"
            disabled={loading.effects.main.mint || mintNumberInvalid}
            onClick={() => {
              mint(new BigNumber(mintNumber).toNumber());
            }}
            style={{ fontFamily: '"Orbitron", sans-serif' }}
          >
            Mint
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPreMintModal}
        style={{
          fontFamily: '"Orbitron", sans-serif !important',
        }}
        onClose={() => {
          setFields({
            showPreMintModal: false,
          });
        }}
      >
        <DialogTitle
          style={{
            background: "#001417",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
            fontFamily: '"Orbitron", sans-serif',
          }}
          onClick={() => {
            openAlert({
              type: "success",
              content: "Congratulations! Transaction Confirmed!",
            });
          }}
        >
          {loading.effects.main.preMint ? (
            <Fragment>
              PENDING...
              <CircularProgress size="24px" style={{ marginLeft: "20px" }} />
            </Fragment>
          ) : (
            <span style={{ fontFamily: '"Orbitron", sans-serif' }}>
              Mint your own BOTHEAD
            </span>
          )}
        </DialogTitle>
        <DialogContent style={{ background: "#001417" }}>
          {loading.effects.main.preMint ? (
            <div className="banner">
              <video
                autoPlay
                loop
                muted
                width="100%"
                className="banner-img"
                preload="auto"
              >
                <source src={BoxMovie} type="video/mp4" />
              </video>
            </div>
          ) : (
            <Fragment>
              <DialogContentText
                style={{
                  marginBottom: "24px",
                  fontFamily: '"Orbitron", sans-serif',
                }}
              >
                <b>PRE MINT MAX MINT NUMBER: {avaliablePreMintNum}</b>
              </DialogContentText>
              <DialogContentText
                style={{
                  marginBottom: "24px",
                  fontFamily: '"Orbitron", sans-serif',
                }}
              >
                After your successfully minting, after a few minutes, you can
                see your BOTHEAD NFT on OpenSea.
              </DialogContentText>
              <TextField
                autoFocus
                error={preMintNumberInvalid}
                margin="dense"
                type="text"
                value={preMintNumber}
                onChange={(e) => {
                  setPreMintNumber(e.target.value);
                }}
                label={`Mint Number`}
                fullWidth
                style={{ fontFamily: '"Orbitron", sans-serif' }}
                variant="standard"
                helperText={preMintNumberInvalid ? "Number invalid." : ""}
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions style={{ background: "#001417" }}>
          <LoadingButton
            loading={loading.effects.main.preMint}
            loadingIndicator="Pending"
            variant="text"
            disabled={loading.effects.main.preMint || preMintNumberInvalid}
            onClick={() => {
              preMint(new BigNumber(preMintNumber).toNumber());
            }}
            style={{ fontFamily: '"Orbitron", sans-serif' }}
          >
            Mint
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
    </div>
  );
}

export default App;
