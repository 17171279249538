import React from "react";
import Countdown from "react-countdown";
import { RootState, RootDispatch } from "../../model";
import { useRematch } from "../../utils/useRematch";
import useInterval from "../../utils/useInterval";
import "./style.css";

const mapState = (state: RootState) => ({
  ...state.main,
  loading: state.loading,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  ...dispatch.main,
});

const preMintStartUnix = 1633717800;

const Hero = () => {
  const [{ preSaleState, publicSaleState }, { setFields, updateInfo }] =
    useRematch(mapState, mapDispatch);

  useInterval(() => {
    updateInfo();
  }, 1000);

  return (
    <>
      <div className="hero_section">
        <div className="hero_container">
          <div className="hero_content hc_1">
            <div className="premint-text">
              {preSaleState ? "PRE MINT STARTED" : "PRE MINT END"}
            </div>
            <button
              onClick={() => {
                if (!preSaleState) {
                  return;
                }
                setFields({
                  showPreMintModal: true,
                });
              }}
            >
              {preSaleState ? (
                <span>PRE MINT NOW</span>
              ) : (
                <Countdown date={preMintStartUnix * 1000}>
                  <span>END</span>
                </Countdown>
              )}
            </button>
          </div>
          <div className="hero_content hc_1" style={{ marginTop: "60px" }}>
            <div className="premint-text">
              {publicSaleState ? "MINT STARTED" : "MINT END:"}
            </div>
            <button
              onClick={() => {
                if (!publicSaleState) {
                  return;
                }
                setFields({
                  showMintModal: true,
                });
              }}
            >
              {publicSaleState ? (
                <span>MINT NOW</span>
              ) : (
                <Countdown date={(preMintStartUnix + 4 * 3600) * 1000}>
                  <span>END</span>
                </Countdown>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
