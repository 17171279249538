import React, { useEffect, useState } from "react";
import { RootState, RootDispatch } from "../../model";
import { useRematch } from "../../utils/useRematch";
import "./Header.css";
// assets
import logo from "../../Images/logo-dark.png";
import menu from "../../Images/menu.png";
import cancel from "../../Images/cancel.png";
import twiterIcon from "../../Images/twitter-icon.png";
import discordIcon from "../../Images/discord-icon.png";
import instagramIcon from "../../Images/instagram.png";

const mapState = (state: RootState) => ({
  ...state.main,
  loading: state.loading,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  ...dispatch.main,
});

const Header = () => {
  const [{ userInfo }, { connectWallet }] = useRematch(mapState, mapDispatch);
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);
  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <>
      <header
        id="top"
        // style={{ height: show ? "100vh" : "max-content" }}
        className={navbar ? "navbar active" : "navbar"}
      >
        <div className="h_container">
          <div className="hl">
            <div className="nav_logo">
              <a id="#top" href="#">
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="title-text">
              <p data-text="bothead collection" className="cyber-title-text">
                bothead collection
              </p>
            </div>
          </div>
          <div className="desk">
            <div className="nav_links">
              <a href="#about">ABOUT</a>
              <a href="#roadmap">ROADMAP</a>
            </div>
            <div className="social_icons">
              <a
                href="https://twitter.com/BotHeadNFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twiterIcon} alt="..." />
              </a>
              <a
                href="https://discord.gg/botheadnft"
                target="_blank"
                rel="noreferrer"
              >
                <img src={discordIcon} alt="..." />
              </a>
              <a
                href="https://www.instagram.com/botheadnft/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="..." />
              </a>
            </div>
            <button
              onClick={() => {
                connectWallet();
              }}
              className="connect_btn"
            >
              {userInfo?.address
                ? `${userInfo.address.substring(
                    0,
                    6
                  )}...${userInfo.address.substring(
                    userInfo.address.length - 6,
                    userInfo.address.length
                  )}`
                : "CONNECT WALLET"}
            </button>
          </div>
        </div>
        <img
          onClick={() => setShow(!show)}
          className="menu"
          src={!show ? menu : cancel}
          alt="..."
        />
        <div
          style={{
            transform: !show
              ? "translate3d(-100%, 0px, 0px)"
              : "translate3d(0px, 0px, 0px)",
            visibility: !show ? "visible" : "visible",
          }}
          className="mob_menu"
        >
          <div className="mb_hl hl">
            <div className="nav_logo">
              <a id="#top" href="#" onClick={() => setShow(false)}>
                <img src={logo} alt="" />
              </a>
            </div>
          </div>
          <div className="hr_2">
            <div className="nav_links">
              {/* close sidebar when clicked on nav links */}
              <a onClick={() => setShow(false)} href="#about">
                ABOUT
              </a>
              <a onClick={() => setShow(false)} href="#roadmap">
                ROADMAP
              </a>
            </div>
            <div className="social_icons">
              <a
                href="https://twitter.com/BotHeadNFT"
                onClick={() => setShow(false)}
                target="_blank"
                rel="noreferrer"
              >
                <img src={twiterIcon} alt="..." />
              </a>
              <a
                href="https://discord.com/invite/tr9VTcpWsa"
                onClick={() => setShow(false)}
                target="_blank"
                rel="noreferrer"
              >
                <img src={discordIcon} alt="..." />
              </a>
              <a
                href="https://www.instagram.com/botheadnft/"
                onClick={() => setShow(false)}
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="..." />
              </a>
            </div>
            {/* close sidebar when clicked on button */}
            <button
              onClick={() => {
                setShow(false);
                connectWallet();
              }}
              className="connect_btn"
            >
              {userInfo?.address
                ? `${userInfo.address.substring(
                    0,
                    6
                  )}...${userInfo.address.substring(
                    userInfo.address.length - 6,
                    userInfo.address.length
                  )}`
                : "CONNECT WALLET1"}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
