import React from "react";
import "./style.css";
// react reveal
import Fade from "react-reveal/Fade";

// assets
import abtImg1 from "../../Images/clouds donuts starry night.webp";
import abtImg2 from "../../Images/box_img1.jpg";
import abtImg3 from "../../Images/box_img3.jpg";
import abtImg4 from "../../Images/box_img4.jpg";
import gifBox from "../../Images/gif.gif";
import Banner from "./Banner";

const AboutSection = () => {
  return (
    <>
      <div id="about" className="about_section">
        <Fade down duration={1000} delay={1100}>
          <p>
            The BOTHEAD Collection is the pictorial representation of the 10,000
            BOTHEADs that have mysteriously appeared on Earth to celebrate the
            beauty of our planet.
          </p>
        </Fade>
        <Fade up duration={1000} delay={1100}>
          <p>
            <b>
              FOR MORE INFO AND DETAILS ON SALE, PRESALE, AND HOW TO MINT EITHER
              FROM OUR SITE OR DIRECTLY FROM OUR CONTRACT, PLEASE VISIT OUR
              DISCORD AND READ ANNOUNCEMENTS AND INFORMATION CHANNELS! THIS IS
              ESPECIALLY IMPORTANT FOR FIRST-TIME MINTERS! (you can always ask
              admins/mods for help!)
            </b>
          </p>
        </Fade>
        <div className="abt_banner">
          <Banner />
        </div>
        <div className="about_content">
          <div className="ab_l">
            <Fade left duration={1000} delay={1100}>
              <div>
                <h2>STORY</h2>
              </div>
            </Fade>
            <Fade up duration={1000} delay={1100}>
              <p>
                BOTHEAD is alive! Shrouded in mystery, the ultra-high-tech
                BOTHEADs are friendly and curious. They have come to experience
                the beauty of life on Earth. Their encounters have inspired a
                deep love and admiration for our world and humanity, which each
                individual BOTHEAD exhibits in its own unique way.
              </p>
            </Fade>
            <Fade left duration={1000} delay={1200}>
              <p>
                Exploring the vast planet, some BOTHEADs have traveled to the
                wilderness to behold nature’s miracles, while others are in the
                cities marveling at the sites. Others still may be found in your
                kitchen or backyard, captivated by the simple pleasures
                available all around us. They are planet Earth’s number one
                fans, and their presence is a sign of greater things yet to
                come!
              </p>
            </Fade>
            <Fade up duration={1000} delay={1200}>
              <p>
                Their mission here is far from over. In fact, we are just
                beginning to understand how they intend to share their advanced
                technologies and secrets of the universe to promote humanity’s
                achievements and boost the beauty of our world to unprecedented
                levels! Big things are on the way, stay tuned!
              </p>
            </Fade>
          </div>
          <div className="ab_r ab_tr">
            <img src={gifBox} alt="..." />
          </div>
        </div>
        <div className="about_content2">
          <div className="ab_l">
            <Fade right duration={1000} delay={1100}>
              <div>
                <h2>SPECS</h2>
              </div>
            </Fade>
            <Fade up duration={1000} delay={1100}>
              <p>
                BOTHEADs are stored as ERC-721 tokens on the Ethereum network
                and hosted on IPFS. Each BOTHEAD is programmatically generated
                from hundreds of 3D rendered traits. While there are literally
                billions of possible combinations, only 10,000 will be made. All
                BOTHEADs are beautiful and high-tech, but some are rarer than
                others. Rarity tools and rankings will be made available upon
                launch and subsequent reveal.
              </p>
            </Fade>
            <Fade right duration={1000} delay={1100}>
              <p>
                Owning a BOTHEAD grants you access to exclusive membership
                benefits, as well as full commercial and creative rights for
                your NFT.
              </p>
            </Fade>
            <Fade up duration={1000} delay={1100}>
              <p>
                The price to mint a BOTHEAD NFT is 0.08 ETH. Minting is random
                and reveal will take place 24-48 hours after the sale. Our smart
                contract will be available at launch.
              </p>
            </Fade>
          </div>
          <div className="ab_r">
            <div>
              <Fade down duration={1000} delay={1220}>
                <div>
                  <img className="glow-earth" src={abtImg1} alt="..." />
                </div>
              </Fade>
              <Fade up duration={1000} delay={1225}>
                <div>
                  <img className="glow-water" src={abtImg2} alt="..." />
                </div>
              </Fade>
            </div>
            <div>
              <Fade up duration={1000} delay={1200}>
                <div>
                  <img className="glow-earth" src={abtImg3} alt="..." />
                </div>
              </Fade>
              <Fade down duration={1000} delay={1200}>
                <div>
                  <img className="glow-water" src={abtImg4} alt="..." />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
