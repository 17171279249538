import React from "react";
import bannerImg1 from "../../Images/Layer 14.png";
import bannerImg2 from "../../Images/Layer 15.png";
import bannerImg3 from "../../Images/Layer 16.png";
import bannerImg4 from "../../Images/Layer 17.png";
import bannerImg5 from "../../Images/Layer 18.png";
const Banner = () => {
  return (
    <div className="banner_section">
      <img src={bannerImg1} alt="..." />
      <img src={bannerImg2} alt="..." />
      <img src={bannerImg3} alt="..." />
      <img src={bannerImg4} alt="..." />
      <img src={bannerImg5} alt="..." />
    </div>
  );
};

export default Banner;
