import React from "react";
import Hero from "../../components/HomeComponents/Hero";
import Banner from "../../components/HomeComponents/Banner";
import Header from "../../Layout/Header/Header";
import Footer from "../../Layout/Footer/index";
import "./style.css";
import AboutSection from "../../components/HomeComponents/AboutSection";
import RoadMap from "../../components/HomeComponents/RoadMap";
const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <div className="line_bg">
        <AboutSection />
        <Banner />
        <RoadMap />
      </div>
      <Footer />
    </>
  );
};

export default Home;
