import React from "react";
import "./style.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <h5>
          Copyright © 2021 Design and developed by
          <a href="https://smashcode.dev/"> BOTHEAD COLLECTION</a>
        </h5>
      </div>
    </>
  );
};

export default Footer;
